<ng-container *ngIf="vm$ | async as vm">
  <uni-box [isMargin]="'top'">
    <app-loading-mask *ngIf="vm.loading"></app-loading-mask>
    <uni-box-content [isVerticalSpace]="false">
      <header>
        <app-span>{{'ci.unishield.blockedWords' | translate}}</app-span>
      </header>
      <app-blocked-cards
        [blocked]="vm.blocked"
        [savings]="vm.savings"
      ></app-blocked-cards>

      <app-countries-table></app-countries-table>
    </uni-box-content>
  </uni-box>
</ng-container>
